import React, { ReactElement, useCallback, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import {
  Button,
  Table,
  TableCell,
  TableHeader,
  TableRow,
} from '@alpha-recycling/component-library';
import { ColumnDef, flexRender, Row, Table as ReactTable } from '@tanstack/react-table';
import { countBy, isNull } from 'lodash';
import useDeepCompareEffect from 'use-deep-compare-effect';

import { withAlphamartIntlProvider } from 'components/shared/AlphamartIntlProvider';
import { HedgePriceInUseBadge } from 'components/shared/HedgePriceInUseBadge';
import {
  DataGridContainer,
  highlightableTextStyles,
  HighlightSearch,
  ItemThumbnail,
} from 'components/shared/List';
import { DataList } from 'components/shared/List/DataList';
import { LoadableContent } from 'components/shared/Loader';
import { NavbarButton } from 'components/shared/Navigation';
import { PanelContent } from 'components/shared/PanelContent/PanelContent';
import { PanelContentMain } from 'components/shared/PanelContentMain/PanelContentMain';
import { PanelContentSidebar } from 'components/shared/PanelContentSidebar/PanelContentSidebar';
import { PanelHeader } from 'components/shared/PanelHeader/PanelHeader';
import { PanelLayout } from 'components/shared/PanelLayout/PanelLayout';
import { MarketHedgePrice } from 'components/shared/Price';
import { ProtectedArea } from 'components/shared/ProtectedArea/ProtectedArea';
import { SearchBar } from 'components/shared/SearchBar/SearchBar';
import { TagGroup } from 'components/shared/TagGroup';
import { Forbidden } from 'components/views/Forbidden/Forbidden';
import { formatDate } from 'helpers/dateTime/dateTime';
import { isTruthy } from 'helpers/isTruthy/isTruthy';
import { FilterableModules, LIST_MODE, ModalFormType, PERMISSIONS, SHARED } from 'shared/constants';
import { useAuthorization } from 'shared/helpers';
import { useAppTable, useCurrentUser, useMediaQuery } from 'shared/hooks';
import { useAlphamartNavigate } from 'shared/hooks/useAlphamartRouter';
import { matchNormalized, matchSampleNameMultiWord } from 'shared/matchers';
import { useGetConverterDetails, useGetConvertersGroupDetails } from 'shared/queries';
import { ApiFile, Converter, ConverterListItem } from 'shared/types';
import { replaceNonAlphanumeric } from 'shared/utils/replaceNonAlphanumeric';
import { fetchConverters } from 'store/convertersListSlice';
import { changePageIndexAction, changePagination } from 'store/listComponentSlice';
import { showModalForm } from 'store/modalFormSlice';
import { saveFilters } from 'store/shared/filtersSlice';
import { useAppDispatch, useAppSelector } from 'store/shared/hooks';
import { MEDIA_QUERY } from 'theme';
import { AveragePrice } from './AveragePrice';
import {
  averagePriceSpacer,
  ConvertersHeaderWrapper,
  DisclaimerContent,
  DisclaimerContentMobile,
} from './ConverterList.styles';
import { ConvertersFilters, ConvertersFiltersShape } from './ConvertersFilters';
import { ConvertersListGridItem } from './ConvertersListGridItem';
import { useSearchableConvertersFields } from './useSearchableConvertersFields';
import { ConverterDetails } from '../ConverterDetails/ConverterDetails';
import { ConvertersGroupDetails } from '../ConvertersGroupDetails/ConvertersGroupDetails';
import useIsCartOpen from '../hooks/useIsCartOpen';
import {
  messages,
  TypedFormattedMessage as FormattedMessage,
  useTypedIntl,
} from '../locale/messages';
import { AddItemsModal } from '../ShoppingCart/AddShoppingCartItemModal/AddItemsModal';
import { ShoppingCart } from '../ShoppingCart/ShoppingCart/ShoppingCart';

type MakeCount = [string, number];

function getMakeNames(input: Converter | ConverterListItem): string[] {
  const converter = input.converterGroup?.converters[0] ?? input;
  return converter?.makesModels?.map(m => m.make?.name).filter(isTruthy) ?? [];
}

function getMakesCount(input: Converter | ConverterListItem): MakeCount[] {
  const makesCount = countBy(getMakeNames(input));
  return Object.entries(makesCount);
}

const shoppingCartButton = (
  <NavbarButton iconName="BasketPrimary" to="/converters/cart" data-guide="shopping-cart-max-md" />
);

const NestedConvertersList = ({
  row: converterRow,
  table,
  onRowClicked,
}: {
  row: Row<Converter | ConverterListItem>;
  table?: ReactTable<Converter | ConverterListItem>;
  onRowClicked?: (item: Converter | ConverterListItem) => void;
}) =>
  converterRow.getCanExpand() && table ? (
    <Table
      header={
        <TableHeader>
          {table.getHeaderGroups()[0].headers.map(({ id: ids, column, getContext }) => (
            <TableCell key={ids}>
              {`${flexRender(column.columnDef.header, getContext()) ?? ''}`}
            </TableCell>
          ))}
        </TableHeader>
      }
      selectableRows={converterRow.getCanSelect()}
      restIndex={1}
      size="auto"
    >
      {converterRow.subRows.map(row => (
        <TableRow
          key={row.id}
          {...(converterRow.getCanSelect() && {
            selectState: {
              onSelectStatusChange: () => row.getCanSelect() && row.toggleSelected(),
              innerProps: { state: row.getIsSelected() },
            },
          })}
        >
          {row.getVisibleCells().map(cell => (
            <TableCell
              key={cell.id}
              onClick={() => !row.getCanSelect() && onRowClicked?.(row.original)}
            >
              {flexRender(cell.column.columnDef.cell, cell.getContext() ?? {}) as ReactElement}
            </TableCell>
          ))}
        </TableRow>
      ))}
    </Table>
  ) : null;

const ConvertersListComponent = (): React.ReactElement => {
  const authorize = useAuthorization();
  const dispatch = useAppDispatch();
  const navigate = useAlphamartNavigate();
  const location = useLocation();
  const intl = useTypedIntl();
  const { id } = useParams<{ id: string }>();
  const converters = useAppSelector(state => state.converters);
  const currentUser = useCurrentUser();
  const savedFilters = useAppSelector(state => state.filters[FilterableModules.CONVERTERS]);
  const listComponent = useAppSelector(state => state.listComponent);
  const [selected, setSelected] = useState<Record<string, boolean>>({});
  const [filtersOpen, setFiltersOpen] = useState(false);
  const [averageMode, setAverageMode] = useState(false);

  const getSearchableConvertersFields = useSearchableConvertersFields();

  const onFiltersToggle = useCallback(() => setFiltersOpen(!filtersOpen), [filtersOpen]);
  const canSeeDisclaimer = authorize(PERMISSIONS.CONVERTERS.DISCLAIMER);

  const isCartOpen = useIsCartOpen();
  const SEARCH_AFTER = 2;

  const onAddToCart = (
    event: React.MouseEvent<HTMLButtonElement>,
    row: Row<Converter | ConverterListItem>,
  ) => {
    event.stopPropagation();
    dispatch(
      showModalForm({
        modalType: ModalFormType.AddShoppingCartItems,
        params: { converters: [row.original] as Converter[] },
      }),
    );
  };

  const columns: ColumnDef<ConverterListItem | Converter>[] = [
    {
      id: 'image',
      header: intl.formatMessage({ id: 'ConvertersList.TableHeader.Image' }),
      cell: ({ row }) => (
        <ItemThumbnail
          attrAlt={
            row.original.converterGroup
              ? row.original.converterGroup.converters.map(c => c.identifier).join(', ')
              : row.original.identifier
          }
          counterfeit={
            row.original.converterGroup
              ? undefined
              : { notes: row.original.notes, show: row.original.counterfeit }
          }
          photo={
            (row.original.converterGroup?.files?.[0] ||
              (row.original as Converter)?.files?.[0] ||
              row.original.converterPhoto) as ApiFile
          }
          size={listComponent.listMode === LIST_MODE.GRID ? 'MEDIUM' : 'LARGE'}
        />
      ),
    },
    {
      id: 'identifier',
      header: intl.formatMessage({ id: 'ConvertersList.TableHeader.Identifier' }),
      cell: ({ row }) => (
        <>
          <div>
            <HighlightSearch
              className={highlightableTextStyles(false)}
              searchWords={[matchNormalized(savedFilters.data.query)!]}
              textToHighlight={
                row.original.converterGroup?.converters?.map(c => c.identifier)?.join(', ') ||
                row.original.converterIdentifier ||
                row.original.identifier
              }
            />
          </div>
          {!row.original.converterGroup && row.original.isPartial && (
            <div>{intl.formatMessage({ id: 'ConvertersList.PartialIdentifier' })}</div>
          )}
        </>
      ),
    },
    {
      id: 'sampleName',
      header: intl.formatMessage({ id: 'ConvertersList.TableHeader.SampleName' }),
      cell: ({ row }) =>
        row.original.converterGroup
          ? null
          : HighlightSearch({
              className: highlightableTextStyles(false),
              searchWords: [matchSampleNameMultiWord(savedFilters.data.query!)!],
              textToHighlight:
                (row.original as Converter).assay?.sampleName ??
                (row.original as ConverterListItem).sampleName,
            }),
    },
    {
      id: 'sampleDate',
      header: intl.formatMessage({ id: 'ConvertersList.TableHeader.SampleDate' }),
      cell: ({ row }) =>
        row.original.converterGroup
          ? null
          : formatDate(
              (row.original as Converter).assay?.sampleDate ??
                (row.original as ConverterListItem).sampleDate,
            ),
    },
    {
      id: 'vehicle',
      header: intl.formatMessage({ id: 'ConvertersList.TableHeader.Vehicle' }),
      cell: ({ row }) => {
        const count = getMakeNames(row.original).length;
        if (!count) return SHARED.LONG_DASH;
        return (
          <TagGroup
            items={getMakesCount(row.original).map(([make, c]) =>
              intl.formatMessage({ id: 'ConvertersList.Vehicles.MakeCount' }, { make, count: c }),
            )}
            countLabelFormatter={() =>
              intl.formatMessage({ id: 'ConvertersList.Vehicles.TotalCount' }, { count })
            }
          />
        );
      },
    },
    {
      id: 'price',
      header: intl.formatMessage({ id: 'ConvertersList.TableHeader.Price' }),
      cell: ({ row }) =>
        MarketHedgePrice({
          price: row.original.converterGroup
            ? {
                hedgePrice: row.original.converterGroup.converters.reduce<number | null>(
                  (acc, curr) =>
                    Number.isFinite(curr.price.hedgePrice)
                      ? (acc as number) + (curr.price.hedgePrice as number)
                      : acc,
                  null,
                ),
                marketPrice: row.original.converterGroup.converters.reduce<number | null>(
                  (acc, curr) =>
                    Number.isFinite(curr.price.marketPrice)
                      ? (acc as number) + (curr.price.marketPrice as number)
                      : acc,
                  null,
                ),
              }
            : row.original.price,
          currentUser,
          prefix: {
            market: intl.formatMessage({ id: 'ConvertersList.MarketPricePrefix' }),
            hedge: intl.formatMessage({ id: 'ConvertersList.HedgePricePrefix' }),
          },
        }),
    },
    {
      id: 'addToCart',
      header: '',
      cell: ({ row }) =>
        !row.getCanExpand() &&
        !isNull(row.original.price.marketPrice) && (
          <Button
            iconName="BasketSecondary"
            variant="plain"
            size="medium"
            content="icon"
            onClick={ev => onAddToCart(ev, row)}
            data-cy={`add-to-cart-${row.original.id}`}
          />
        ),
    },
  ];

  const table = useAppTable<ConverterListItem | Converter>({
    columns,
    data: converters.list,
    getSubRows: row => row.converterGroup?.converters ?? [],
    getRowCanExpand: row => !!row.original.converterGroup?.converters?.length,
    enableRowSelection: averageMode,
    pageCount: Math.ceil(converters.count / listComponent.pageSize),
    onPaginationChange: pagination => dispatch(changePagination(pagination)),
    onRowSelectionChange: setSelected,
    manualPagination: true,
    state: {
      columnVisibility: {
        sampleName: authorize(PERMISSIONS.CONVERTERS.LIST_SAMPLE) && useMediaQuery(MEDIA_QUERY.LG),
        sampleDate: authorize(PERMISSIONS.CONVERTERS.LIST_SAMPLE) && useMediaQuery(MEDIA_QUERY.XXL),
        vehicle: useMediaQuery(MEDIA_QUERY.XL),
        addToCart: authorize(PERMISSIONS.SHOPPING_CART.MAIN),
      },
      pagination: { pageIndex: listComponent.pageIndex, pageSize: listComponent.pageSize },
      rowSelection: selected,
      globalFilter: savedFilters?.data?.query,
    },
  });

  useDeepCompareEffect(() => {
    dispatch(
      fetchConverters(listComponent.pageIndex + 1, listComponent.pageSize, savedFilters.data),
    );
    setAverageMode(false);
    setSelected({});
  }, [listComponent.pageIndex, listComponent.pageSize, savedFilters?.data, currentUser]);

  const enabled = !!id && !isCartOpen;
  const groupMode = location.pathname.includes('group');
  const converter = useGetConverterDetails(id, { enabled: enabled && !groupMode });
  const converterGroup = useGetConvertersGroupDetails(id, { enabled: enabled && groupMode });

  const setPageIndex = (currentPage: number) => dispatch(changePageIndexAction(currentPage));

  const onFiltersChanged = (changed: Partial<ConvertersFiltersShape>) => {
    if (changed.query && replaceNonAlphanumeric(changed.query).length < SEARCH_AFTER) {
      return;
    }

    setPageIndex(0);

    dispatch(saveFilters({ filterKey: FilterableModules.CONVERTERS, values: changed }));
  };

  const onConverterClick = (item: ConverterListItem | Converter) => {
    setFiltersOpen(false);
    navigate(
      item?.converterGroup?.converters?.length
        ? `/converters/group/${item.converterGroup?.id}`
        : `/converters/${item.id}`,
    );
  };

  const goToPartialConverters = () => navigate('/converters/partial');

  const onAverageModeToggle = () => {
    setAverageMode(!averageMode);
  };

  const loadingEnabled = !converters.list && !converter.isFetching;

  const isPhone = useMediaQuery(MEDIA_QUERY.MAX_SM);

  if (isCartOpen && !authorize(PERMISSIONS.SHOPPING_CART.MAIN)) {
    return <Forbidden />;
  }

  return (
    <PanelLayout navbarChildren={shoppingCartButton}>
      {canSeeDisclaimer && (
        <DisclaimerContentMobile>
          <FormattedMessage id="ConvertersList.HeaderList.Disclaimer" />
        </DisclaimerContentMobile>
      )}
      <ConvertersHeaderWrapper>
        <PanelHeader title={<FormattedMessage id="ConvertersList.Header" />}>
          {canSeeDisclaimer && (
            <DisclaimerContent>
              <FormattedMessage id="ConvertersList.HeaderList.Disclaimer" />
            </DisclaimerContent>
          )}
          <HedgePriceInUseBadge />
          <ProtectedArea permission={PERMISSIONS.CONVERTERS.PARTIAL_CONVERTERS}>
            <Button
              variant="filled"
              content="text"
              onClick={goToPartialConverters}
              label={intl.formatMessage({
                id: `ConvertersList.${isPhone ? 'Grid.Partial' : 'PartialConverters'}`,
              })}
            />
          </ProtectedArea>
        </PanelHeader>
      </ConvertersHeaderWrapper>
      <PanelContent>
        <PanelContentMain disableScroll={loadingEnabled} className={averagePriceSpacer}>
          <SearchBar
            onSearchChanged={onFiltersChanged}
            searchAfter={SEARCH_AFTER}
            onFiltersToggle={onFiltersToggle}
            initialQuery={savedFilters.data.query}
            chooseListType
            isSearchPending={converters.isPending}
            addSearchSession
            savedFilters={savedFilters}
          />
          <DataList
            table={table}
            size="auto"
            restIndex={1}
            selectable={averageMode}
            isLoading={converters.isPending}
            onRowClicked={onConverterClick}
            subTables={[NestedConvertersList]}
            {...(listComponent.listMode === LIST_MODE.GRID && {
              gridRowComponent: ConvertersListGridItem,
              gridBodyComponent: DataGridContainer(listComponent.columnsQuantity),
            })}
            getSearchableFields={getSearchableConvertersFields}
          />
          {!converters.isPending && !!converters?.count && !savedFilters?.data.showComingSoon && (
            <AveragePrice
              enabled={averageMode}
              onAverageModeToggle={onAverageModeToggle}
              onSelectAll={() => table.toggleAllRowsSelected(true)}
              onClear={() => table.toggleAllRowsSelected(false)}
              converters={table.getSelectedRowModel().flatRows.map(p => p.original as Converter)}
            />
          )}
        </PanelContentMain>
        <PanelContentSidebar
          header={intl.formatMessage({ id: 'Global.Filters' })}
          headerIcon="Filter"
          open={filtersOpen}
          onSidebarClosed={onFiltersToggle}
        >
          <ConvertersFilters
            onFiltersChanged={onFiltersChanged}
            onFiltersApplied={onFiltersToggle}
          />
        </PanelContentSidebar>
      </PanelContent>
      <LoadableContent loading={converter.isFetching}>
        <ConverterDetails converter={converter.data ?? null} currentUser={currentUser} />
      </LoadableContent>
      <LoadableContent loading={converterGroup.isFetching}>
        <ConvertersGroupDetails group={converterGroup.data ?? null} currentUser={currentUser} />
      </LoadableContent>
      <ShoppingCart />
      <AddItemsModal />
    </PanelLayout>
  );
};

export const ConvertersList = withAlphamartIntlProvider(ConvertersListComponent, messages);
